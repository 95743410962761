import { Button } from '@components/Core/Button'
import { Flex } from '@components/Core/Display'
import { Input } from '@components/Core/Inputs/Input'
import { Translator } from '@components/Core/Translator'
import { LoadingIcon } from '@components/Shared/LoadingIcon'
import { Formik, FormikProps } from 'formik'
import Link from 'next/link'
import { ChangeEvent, FC, useCallback, useRef } from 'react'
import { validationSchemaFormLogin } from './config'
import { FormLoginFields, useFormLogin } from './hook'

export const LoginForm: FC = () => {
  const {
    changeFields,
    fields,
    onSubmit,
    loading,
    hiddenPassword,
    handleHiddenPassword,
  } = useFormLogin()
  const formikRef = useRef<FormikProps<FormLoginFields>>(null)

  const handleChangeInputField = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!formikRef.current) return

      changeFields(
        e.target.getAttribute('name') ?? '',
        e.target.value,
        e,
        formikRef.current?.handleChange
      )
    },
    [fields, formikRef.current]
  )

  return (
    <Flex className="flex-col gap-8">
      <Flex className="flex-col gap-2 items-center">
        <Translator
          Wrapper="h2"
          className="text-xl text-primary-500 font-bold"
          path="login.form.title"
        />
        <Translator
          Wrapper="h3"
          className="text-base text-light-gray-500 font-semibold text-center w-max"
          path="login.form.description"
        />
      </Flex>
      <Formik
        initialValues={fields}
        validationSchema={validationSchemaFormLogin}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ handleBlur, handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <Input
              label="login.form.email.label"
              placeholder="login.form.email.placeholder"
              name="email"
              className="w-[80vw] lg:w-full"
              required
              value={fields.email}
              onBlur={handleBlur}
              variant={touched.email && errors.email ? 'error' : 'default'}
              helperText={touched.email && errors.email}
              onChange={handleChangeInputField}
              data-testid="txt-email"
            />
            <Input
              label="login.form.password.label"
              placeholder="********"
              name="password"
              type={hiddenPassword ? 'password' : 'text'}
              required
              data-testid="txt-password"
              value={fields.password}
              className="w-[80vw] lg:w-full"
              endAdornment={{
                element: (
                  <button
                    type="button"
                    className={`fa fa-eye cursor-pointer ${
                      !fields.password && 'hidden'
                    }`}
                    onClick={handleHiddenPassword}
                  />
                ),
              }}
              variant={
                touched.password && errors.password ? 'error' : 'default'
              }
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChangeInputField}
            />
            <Flex className="items-start lg:items-center gap-6 lg:gap-20 justify-start mt-28px lg:justify-between font-semibold flex-col lg:flex-row">
              <Flex className="text-base items-center gap-2 text-dark-gray-500">
                <input type="checkbox" />
                <Translator path="login.form.withConnect.text" />
              </Flex>
              <span className="text-base text-primary-300 hover:text-primary-500">
                <Link href="/change-password" passHref>
                  <Translator
                    Wrapper="a"
                    path="login.form.forgotPassword.text"
                  />
                </Link>
              </span>
            </Flex>
            <Button
              data-testid="btn-login"
              type="submit"
              className="mt-28px text-base"
              startAdornment={
                loading ? { element: <LoadingIcon /> } : undefined
              }
            >
              <Translator path="login.form.submit.text" />
            </Button>
          </form>
        )}
      </Formik>
    </Flex>
  )
}
