export const DAY_PATH_STRING: { [key: string]: string } = {
  0: 'core.dateTime.day.sunday',
  1: 'core.dateTime.day.monday',
  2: 'core.dateTime.day.tuesday',
  3: 'core.dateTime.day.wednesday',
  4: 'core.dateTime.day.thursday',
  5: 'core.dateTime.day.friday',
  6: 'core.dateTime.day.saturday',
}

export const LONG_DAY_PATH_STRING: { [key: string]: string } = {
  0: 'core.dateTime.longDay.sunday',
  1: 'core.dateTime.longDay.monday',
  2: 'core.dateTime.longDay.tuesday',
  3: 'core.dateTime.longDay.wednesday',
  4: 'core.dateTime.longDay.thursday',
  5: 'core.dateTime.longDay.friday',
  6: 'core.dateTime.longDay.saturday',
}

export const MONTH_PATH_STRING: { [key: string]: string } = {
  0: 'core.dateTime.month.january',
  1: 'core.dateTime.month.february',
  2: 'core.dateTime.month.march',
  3: 'core.dateTime.month.april',
  4: 'core.dateTime.month.may',
  5: 'core.dateTime.month.june',
  6: 'core.dateTime.month.july',
  7: 'core.dateTime.month.august',
  8: 'core.dateTime.month.september',
  9: 'core.dateTime.month.october',
  10: 'core.dateTime.month.november',
  11: 'core.dateTime.month.december',
}

export const REQUEST_STATUS = {
  DRAFT: 'draft',
  SCREENING: 'screening',
  TREATMENT_SUGGESTION: 'treatment_suggestion',
  PROJECT: 'engineering_design',
  CANCELED: 'canceled',
  DECLINED: 'declined',
  FINISHED: 'finished',
  WAITING_SURGERY: 'waiting_for_the_surgery',
  PROTOTYPE: 'prototype',
  ENDOPROSTHESIS_MANUFACTURING: 'endoprosthesis_manufacturing',
  LOGISTIC: 'logistics',
}

export const REQUEST_SUB_STATUS = {
  DRAFT: {
    WAITING: 'draft_waiting',
  },
  SCREENING: {
    WAITING: 'screening_waiting',
    PRE_ANALYSIS: 'screening_in_pre_analysis_of_informations',
    TOMOGRAPHY_ANALYSIS: 'screening_tomography_analysis',
    APPROVED: 'screening_approved',
    PRE_ANALYSIS_DISAPPROVED: {
      REGISTRATION_PROBLEMS: 'screening_registration_problems',
      NO_CONSTRAST: 'screening_disapproved_no_contrast',
      CUTS_LARGER_THAN_1MM: 'screening_disapproved_cuts_larger_than_1mm',
      ACQUISITION_DATE_OVER_6_MONTHS:
        'screening_disapproved_acquisition_date_over_6_months',
      MOTION_ARTIFACT_3D: 'screening_disapproved_motion_artifact_3d',
      IMPOSSIBILITY_OF_ANALYSIS_THE_3D_RECONSTRUCTION:
        'screening_disapproved_impossibility_of_analyzing_the_3d_reconstruction',
      EMPTY_FILE: 'screening_disapproved_empty_file',
      OTHERS: 'screening_disapproved_others',
    },
    TOMOGRAPHY_ANALYSIS_DISAPPROVED: {
      NO_LAP_FOR_STANDARD_CARE:
        'screening_tomography_analysis_disapproved_no_lap_for_standard_care',
      NO_ANATOMICAL_INDICATION_FOR_CUSTOMIZED_TREATMENT:
        'screening_tomography_analysis_disapproved_no_anatomical_indication_for_customized_treatment',
      EXCESSIVE_CROOKEDNESS:
        'screening_tomography_analysis_disapproved_excessive_crookedness',
      NO_PATIENT_ACESS:
        'screening_tomography_analysis_disapproved_no_patient_access',
      PRODUCTIVE_IMPOSSIBILITY:
        'screening_tomography_analysis_disapproved_productive_impossibility',
      OTHERS: 'screening_tomography_analysis_disapproved_others',
    },
  },
  TREATMENT_SUGGESTION: {
    DRAFT: 'treatment_suggestion_draft',
    WAITING: 'treatment_suggestion_waiting',
    DISAPPROVED: {
      DOES_NOT_AGREE_WITH_THE_PROPOSAL:
        'treatment_suggestion_disapproved_does_not_agree_with_the_proposal',
      PAYING_SOURCE_DID_NOT_ACCEPT:
        'treatment_suggestion_disapproved_paying_source_did_not_accept',
      APPROVED_BY_ANOTHER_COMPANY:
        'treatment_suggestion_disapproved_approved_by_another_company',
      OTHERS: 'treatment_suggestion_disapproved_others',
    },
    APPROVED: 'treatment_suggestion_approved',
    DECLINED: {
      DICOM: 'treatment_suggestion_repproved_dicom',
    },
  },
  PROJECT: {
    SCHEDULE: 'engineering_design_awaiting_schedule',
    DRAFT: 'engineering_design_draft',
    WAITING: 'engineering_design_waiting',
    APPROVED_SPECIALIST: 'engineering_design_approved_specialist',
    APPROVED: 'engineering_design_approved',
    REPROVED_SPECIALIST: 'engineering_design_disapproved_specialist',
    REPROVED_REQUESTER: 'engineering_design_disapproved_requester',
    WAITING_SCHEDULE: 'engineering_design_waiting_schedule',
    ANALYSIS: 'engineering_design_analisys',
    ANALISYS_PRODUCTION: 'engineering_project_analisys_production',
    SETTINGS_PRODUCTION: 'engineering_production_settings_production',
    APPROVED_PRODUCTION: 'engineering_approved_production',
    REPROVED_PRODUCTION: 'engineering_repproved_production',
  },
  PROTOTYPE: {
    WAITING: 'prototype_waiting',
  },
  ENDOPROSTHESIS_MANUFACTURING: {
    WAITING: 'endoprosthesis_manufacturing_waiting',
  },
}

export const PAGES_LINKS = {
  home: '/dashboard',
  requestsList: '/projects-requests',
  request: `/request`,
  login: '/login',
  users: '/users',
  draftsman: '/draftsman',
  scheduleRequest: (requestId?: number) =>
    `/schedule${requestId ? `?id=${requestId}` : ''}`,
  analysisRequest: (requestId?: number) =>
    `/draftsman/analysis/${requestId ?? ''}`,
  projectEngineer: '/production-analyst',
}

export const NUMBER_OF_PROJECTS_PER_DAY = 4
