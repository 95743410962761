import { Head } from '@components/Features/Head'
import { AuthLayout } from '@components/Features/Layouts/Auth'
import { LoginForm } from '@components/Features/Pages/Login/Form'
import { useLanguageQuery } from '@utils/hooks/useLanguage'
import { ReactElement } from 'react'
import { NextPageWithLayout } from 'types/app'

const Login: NextPageWithLayout = () => {
  useLanguageQuery()

  return <LoginForm />
}

Login.getLayout = (page: ReactElement, pageProps: any) => (
  <AuthLayout image="/images/doctor_heart.png" {...pageProps}>
    <Head title="login.page.title" />
    {page}
  </AuthLayout>
)

export default Login
