import { apiGateway } from '@configs/api'
import { AxiosResponse } from 'axios'

type RequestBody = {
  email: string
  password: string
}

type ResponseData = {
  name: string
  email: string
  token: string
}

export const login = async (body: RequestBody): Promise<void> => {
  const auth = await apiGateway.post<AxiosResponse<ResponseData>, RequestBody>({
    url: `/auth/signin`,
    body,
  })

  apiGateway.updateAuthTokens({ accessToken: auth.data.token })
}
