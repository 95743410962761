import { PAGES_LINKS } from '@configs/constants'
import { useToast } from '@states/stores/toast'
import { useRouter } from 'next/router'
import { useCallback, useState, FormEvent } from 'react'
import { login } from 'src/fetchers/auth/login'

export type FormLoginFields = {
  email: string
  password: string
  [key: string]: string
}

export const useFormLogin = () => {
  const [fields, setFields] = useState<FormLoginFields>({
    email: '',
    password: '',
  })
  const [hiddenPassword, setHiddenPassword] = useState(true)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const toast = useToast()

  const onSubmit = useCallback(async (values: FormLoginFields) => {
    setLoading(true)
    try {
      await login(values)
      router.push(PAGES_LINKS.home)
    } catch (err: any) {
      setLoading(false)

      if (err.response.status === 400) {
        toast.open(
          'login.errors.e-mail_or_password_incorrect.title',
          'login.errors.e-mail_or_password_incorrect.message',
          'error'
        )
        return
      }

      if (err.response.status === 401) {
        toast.open(
          'login.errors.e-mail_or_password_incorrect.title',
          'login.errors.e-mail_or_password_incorrect.message',
          'error'
        )
        return
      }

      toast.open(
        'login.errors.generic.title',
        'login.errors.generic.message',
        'error'
      )
    }
  }, [])

  const changeFields = useCallback(
    (
      field: string,
      value: string,
      e: FormEvent<HTMLInputElement>,
      callbackFormik: (event: FormEvent<HTMLInputElement>) => void
    ) => {
      setFields((prev) => ({
        ...prev,
        [field]: value,
      }))
      callbackFormik(e)
    },
    []
  )

  const handleHiddenPassword = useCallback(
    () => setHiddenPassword((prev) => !prev),
    []
  )

  return {
    fields,
    onSubmit,
    changeFields,
    loading,
    hiddenPassword,
    handleHiddenPassword,
  }
}
